import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { UiText, IconButton, Popover } from "."
const NavLink = styled(Link)`
  margin: 0;
  cursor: pointer;
  color: #fff;
  &:hover {
    text-decoration: none;
    border-bottom: 2px solid #fff;
  }
  &.current-page {
    border-bottom: 2px solid #fff;
  }
`
const NavigationList = styled.nav`
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;
  align-items: center;
  justify-content: flex-end;
  ul {
    list-style: none;
    display: flex;
    flex: 1;
    height: 100%;
    margin: 0;
    align-items: center;
    justify-content: flex-end;
    li {
      display: none;
      padding: 0 8px;
      &.small {
        display: block;
      }
      @media(min-width: 768px) {
        display: block;
        &.small {
          display: none;
        }
      }
    }
  }
`
const Header = ({ siteTitle }) => {
  const [mode, setMode] = useState(true)
  const [menu, openMenu] = useState(false)
  return (
    <header
      css={css`
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #68cf89;
        position: fixed;
        top: 0;
        z-index: 1;
        background-color: #63c46e;
        /* background-image: linear-gradient(to top, #63c46e 2rem, #68cf89); */
      `}
    >
      <div
        css={css`
          max-width: 1170px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <UiText
          variant="h2"
          as="h2"
          css={css`
            color: #555;
            padding: 0 8px;
          `}
          textColor="#fff"
        >
          <Link to="/" style={{ color: "#fff" }}>
            {siteTitle}
          </Link>
        </UiText>
        <NavigationList>
          <ul>
            <li>
              <NavLink to="/" activeClassName="current-page">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog" activeClassName="current-page">
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="current-page">
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="current-page">
                Contact
              </NavLink>
            </li>
            <li>
              <Popover
                content={mode ? "Dark mode" : "Light mode"}
                placement="top"
              >
                <IconButton
                  icon={mode ? "IconMoon" : "IconSun"}
                  onClick={() => setMode(!mode)}
                />
              </Popover>
            </li>
            <li className="small">
              <IconButton icon="IconMenu" onClick={() => openMenu(!menu)} />
            </li>
          </ul>
        </NavigationList>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
