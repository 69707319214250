/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "styled-components"
import Header from "../components/header"
import { GlobalStyle, theme } from "../utils"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle theme={theme} />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main
        css={css`
          margin-top: 0;
        `}
      >
        {children}
      </main>
      <footer
        css={css`
          position: relative;
          background-image: linear-gradient(to top, #63c46e 2rem, #63c46e);
          margin: 0;
          .custom-shape-divider-top-1614059162 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
            transform: rotate(180deg);
          }

          .custom-shape-divider-top-1614059162 svg {
            position: relative;
            display: block;
            width: calc(111% + 1.3px);
            height: 42px;
          }

          .custom-shape-divider-top-1614059162 .shape-fill {
            fill: #ffffff;
          }
        `}
      >
        <div class="custom-shape-divider-top-1614059162">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <div css={css`margin: 0; height: 200px;`}>
          Test
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
