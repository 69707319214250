import { createGlobalStyle } from "styled-components"
import normalize from "normalize.css"
export const GlobalStyle = createGlobalStyle`
${normalize}
    :root {
      --f-color-brandPrimary: #63c46e;
    --f-color-brandPrimary--light: #68cf89;
  --f-color-brandPrimary--inactive: #a8dadc;
  --f-color-brandSecondary: #e63946;
  --f-color-brandSecondary--light: #bd4f6c;

  --f-color-icon--white: #fff;
  --f-color-icon--disabled: #dedede;
  --f-color-icon--dark: #190134;

  --f-color-element--primary: #fff;
  --f-color-element--secondary: #68cf89;
  --f-color-element--tertiary: #68cf89;
  --f-color-element--inactive: #eaeaea;
  --f-color-element--error: #ee7d52;
  --f-color-element--success: #5cc689;
  --f-color-element--overlay: #2b1e70;

  --f-color-text--primary: #190134;
  --f-color-text--secondary: #68cf89;
  --f-color-text--tertiary: #68cf89;
  --f-color-text--link: #252d9b;
  --f-color-text--white: #fff;
  --f-color-text--lightGrey: #f2f2f2;
}

:root {
  --f-fontSize--xxs: 0.625rem; /* 10px */
  --f-fontSize--xs: 0.75rem; /* 12px */
  --f-fontSize--s: 0.875rem; /* 14px */
  --f-fontSize--m: 1rem; /* 16px */
  --f-fontSize--l: 1.25rem; /* 20px */
  --f-fontSize--xl: 1.5rem; /* 24px */
  --f-fontSize--xxl: 2rem; /* 32px */
}

:root {
  --f-fontWeight--normal: 400;
  --f-fontWeight--bold: 700;
  --f-fontWeight--black: 800;
}

:root {
  --f-lineHeight--s: 1rem;
  --f-lineHeight--m: 1.4rem;
  --f-lineHeight--l: 1.5rem;
  --f-lineHeight--xl: 2rem;
}

:root {
  --f-iconSize--s: 0.75rem; /* 12px */
  --f-iconSize--m: 1rem; /* 16px */
  --f-iconSize--l: 1.25rem; /* 20px */
}

:root {
  --f-borderRadius--xl: 1.5rem; /* 24px */
  --f-borderRadius--l: 1rem; /* 16px */
  --f-borderRadius--m: 0.5rem; /* 8px */
  --f-borderRadius--s: 0.25rem; /* 4px */
}

:root {
  --f-space--s: 0.25rem; /* 4px */
  --f-space--m: 0.5rem; /* 8px */
  --f-space--l: 0.75rem; /* 12px */
  --f-space--xl: 1rem; /* 16px */
  --f-space--xxl: 1.5rem; /* 24px */
}

:root {
  --f-breakpoint--s: 28.75rem; /* 460px */
  --f-breakpoint--m: 50rem; /* 800px */
  --f-breakpoint--l: 68.75rem; /* 1100px */
}

:root {
  --f-zIndex--Overlay: 100;
  --f-FileUploader-height: 8.125rem; /* 130px */
}

* {
  box-sizing: border-box;
  margin: 0;
}
/* More info: https://bit.ly/2PsCnzk */
* + * {
  margin-top: 1rem;
}
html,
body {
margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 1.4;
  color: #555;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  @media (min-width: calc(550px + 10vw)) {
    font-size: 18px;
  }
  /* remove margin for the main div that Gatsby mounts into */
  > div {
    margin-top: 0;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  line-height: 1.1;
  + * {
    margin-top: 0.5rem;
  }
}
strong {
  color: #222;
}
li {
  margin-top: 0.25rem;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

`
export default GlobalStyle